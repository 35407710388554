import { createSlice } from '@reduxjs/toolkit';
import { generateSetEditId } from '../../generators/generateReducers';
import { generateStateSingle } from '../../generators/generateState';
import {
    createData,
    deleteData,
    editData,
    fetchData,
    fetchEditData,
} from '../../generators/generateThunks';
const SLICE_NAME = 'approval';

export const fetchApprovals = fetchData.generateThunk(SLICE_NAME, SLICE_NAME);
export const editApproval = editData.generateThunk(SLICE_NAME, SLICE_NAME);
export const createApproval = createData.generateThunk(SLICE_NAME, SLICE_NAME);
export const deleteApproval = deleteData.generateThunk(SLICE_NAME, SLICE_NAME);
export const fetchApproval = fetchEditData.generateThunk(
    SLICE_NAME,
    SLICE_NAME
);

const approvalsSlice = createSlice({
    name: SLICE_NAME,
    initialState: generateStateSingle(SLICE_NAME),
    extraReducers: {
        ...fetchData.generateExtraReducers(fetchApprovals, SLICE_NAME),
        ...editData.generateExtraReducers(editApproval, SLICE_NAME),
        ...createData.generateExtraReducers(createApproval, SLICE_NAME),
        ...deleteData.generateExtraReducers(deleteApproval, SLICE_NAME),
        ...fetchEditData.generateExtraReducers(fetchApproval, SLICE_NAME),
    },
});

export const getApproval = (state) => state.approval;
export default approvalsSlice.reducer;
