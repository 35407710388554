import { FieldArray, FormikProvider, useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import PageWithCard from '../../components/infrastructure/PageWithCard';
import PrimaryButton from '../../components/infrastructure/Buttons/PrimaryButton';
import FormikSelectGroup from '../../components/formik/FormikSelectGroup';
import {
    fetchCustomer,
    fetchCustomers,
    getCustomers,
} from '../../app/reducers/Customers/customerSlice';
import { generateOptions } from '../../utils/Utils';
import QueryString from 'qs';
import { authAxiosInstance } from '../../utils/axiosConfig';
import FormikAsyncSelect from '../../components/formik/FormikAsyncSelect';
import FormikTextareaGroup from '../../components/formik/FormikTextareaGroup';
import FormikDirectImageUpload from '../../components/formik/FormikDirectImageUpload';
import { createComplaint } from '../../app/reducers/Complaints/complaintSlice';
import DangerButton from '../../components/infrastructure/Buttons/DangerButton';
import FormikDirectFileUpload from '../../components/formik/FormikDirectFileUpload';
import { getAuth } from '../../app/reducers/Auth/authSlice';

const AddComplaint = () => {
    const { elementEditData, editDataLoading } = useSelector(getCustomers);
    const { user } = useSelector(getAuth);
    const [customerOptions, setCustomerOptions] = useState([]);
    const dispatch = useDispatch();

    const formik = useFormik({
        initialValues: {
            company: '',
            customer: '',
            product: '',
            description: '',
            photos: [],
            videos: [],
        },
        validationSchema: Yup.object({
            customer: Yup.string(),
            description: Yup.string(),
            photo: Yup.string(),
        }),
        onSubmit: async (values) => {
            await dispatch(createComplaint(values));
            formik.resetForm();
        },
    });

    useEffect(() => {
        dispatch(
            fetchCustomer({
                _id: formik?.values?.customer ?? '',
                populate: true,
            })
        );
    }, [JSON.stringify(formik?.values?.customer)]);
    return (
        <PageWithCard heading="Add Complaint">
            <form
                onSubmit={formik.handleSubmit}
                className="flex flex-col gap-3"
            >
                <FormikAsyncSelect
                    label="Select Company"
                    name={'company'}
                    formik={formik}
                    getOptions={async (value) => {
                        try {
                            const string = QueryString.stringify({
                                search: value,
                            });
                            const response = await authAxiosInstance.get(
                                `/company?${string}`
                            );
                            const options = response?.data?.data?.docs?.map(
                                (ele) => ({
                                    label: ele.name,
                                    value: ele._id,
                                })
                            );

                            return options;
                        } catch (error) {
                            console.log(error);
                        }
                    }}
                    onChange={async (selectedOption) => {
                        const searchPayload = {
                            company: selectedOption.value,
                        };
                        const string = QueryString.stringify(searchPayload);
                        const customerResp = await authAxiosInstance.get(
                            `/customer?${string}`
                        );
                        const customerDocs = customerResp?.data?.data?.docs
                            ? customerResp?.data?.data?.docs
                            : [];
                        setCustomerOptions(customerDocs);
                        formik.setFieldValue('company', selectedOption.value);
                        formik.setFieldValue(
                            'customer',
                            customerDocs?.[0]?._id
                        );
                    }}
                />
                {formik.values.company && (
                    <>
                        <FormikSelectGroup
                            label="Select Customer"
                            name="customer"
                            formik={formik}
                            options={generateOptions({
                                array: customerOptions,
                                labelField: 'name',
                                valueField: '_id',
                            })}
                        />
                        {formik?.values?.customer && (
                            <FormikSelectGroup
                                label="Select Machine"
                                name="product"
                                formik={formik}
                                options={generateOptions({
                                    array: elementEditData?.machine ?? [],
                                    labelField: 'name',
                                    valueField: '_id',
                                })}
                            />
                        )}
                        {formik?.values?.product && (
                            <FormikTextareaGroup
                                name="description"
                                formik={formik}
                                label="Description"
                            />
                        )}
                        <p>Photos</p>
                        {formik?.values?.product && (
                            <FormikProvider value={formik}>
                                <FieldArray
                                    name="photos"
                                    render={(arrayHelpers) => {
                                        return (
                                            <div className="flex flex-col">
                                                {formik.values.photos.map(
                                                    (ele, i) => (
                                                        <div className="flex items-center gap-2">
                                                            <FormikDirectImageUpload
                                                                label={`photo ${i + 1}`}
                                                                formik={formik}
                                                                name={`photos.${i}`}
                                                                randomize
                                                                location={
                                                                    'iml_group/complaints/'
                                                                }
                                                            />
                                                            <div>
                                                                <DangerButton
                                                                    onClick={() => {
                                                                        arrayHelpers.remove(
                                                                            i
                                                                        );
                                                                    }}
                                                                >
                                                                    Remove
                                                                </DangerButton>
                                                            </div>
                                                        </div>
                                                    )
                                                )}
                                                <div>
                                                    <PrimaryButton
                                                        onClick={() => {
                                                            arrayHelpers.push(
                                                                ''
                                                            );
                                                        }}
                                                    >
                                                        Add Photo
                                                    </PrimaryButton>
                                                </div>
                                            </div>
                                        );
                                    }}
                                />
                            </FormikProvider>
                        )}
                        <p>Videos</p>

                        {formik?.values?.product && (
                            <FormikProvider value={formik}>
                                <FieldArray
                                    name="videos"
                                    render={(arrayHelpers) => {
                                        return (
                                            <div className="flex flex-col">
                                                {formik.values.videos.map(
                                                    (ele, i) => (
                                                        <div className="flex items-center gap-2">
                                                            <FormikDirectFileUpload
                                                                label={`video ${i + 1}`}
                                                                formik={formik}
                                                                name={`videos.${i}`}
                                                                randomize
                                                                location={
                                                                    'iml_group/complaints/'
                                                                }
                                                            />
                                                            <div>
                                                                <DangerButton
                                                                    onClick={() => {
                                                                        arrayHelpers.remove(
                                                                            i
                                                                        );
                                                                    }}
                                                                >
                                                                    Remove
                                                                </DangerButton>
                                                            </div>
                                                        </div>
                                                    )
                                                )}
                                                <div>
                                                    <PrimaryButton
                                                        onClick={() => {
                                                            arrayHelpers.push(
                                                                ''
                                                            );
                                                        }}
                                                    >
                                                        Add Video
                                                    </PrimaryButton>
                                                </div>
                                            </div>
                                        );
                                    }}
                                />
                            </FormikProvider>
                        )}
                    </>
                )}

                <div>
                    <PrimaryButton type="submit">Submit</PrimaryButton>
                </div>
            </form>
        </PageWithCard>
    );
};

export default AddComplaint;
