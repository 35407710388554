import React, { useEffect, useState } from 'react';
import ModalBasic from '../../components/infrastructure/Modals/ModalBasic';
import { useFormik } from 'formik';
import SpareParts from './SpareParts';
import PrimaryButton from '../../components/infrastructure/Buttons/PrimaryButton';
import FormikMultiSelect from '../../components/formik/FormikMultiSelect';
import { useDispatch, useSelector } from 'react-redux';
import FormikInputGroup from '../../components/formik/FormikInputGroup';
import FormikSelectGroup from '../../components/formik/FormikSelectGroup';
import { getProducts } from '../../app/reducers/Products/productSlice';
import { authAxiosInstance } from '../../utils/axiosConfig';
import { toast } from 'react-toastify';
import {
    editSparePart,
    fetchSparePart,
    fetchSpareParts,
} from '../../app/reducers/SparePart/sparePartSlice';

const AddSparePartToMachine = ({
    partsData,
    machineId,
    isSparePartAddModalOpen,
    setIsSparePartAddModalOpen,
}) => {
    const [spareData, setPartsData] = useState([]);
    const { products } = useSelector(getProducts);

    const dispatch = useDispatch();

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            machine: machineId ? machineId : null,
            spareParts: partsData ? partsData : [],
        },
        onSubmit: async (values) => {
            if (values?.spareParts?.length === 0) {
                toast.error('No Spare part selected');
                return;
            }
            if (values?.spareParts?.length > 0) {
                for (let i = 0; i < values?.spareParts?.length; i++) {
                    let spareID = values?.spareParts[i];
                    if (!partsData.includes(spareID)) {
                        let res = await dispatch(
                            fetchSparePart({ _id: spareID })
                        );
                        if (res?.payload?.success) {
                            let fetchObj = res?.payload?.data?.docs[0];
                            let editObj = {
                                ...fetchObj,
                                machine: [...fetchObj.machine, values?.machine],
                            };
                            await dispatch(editSparePart(editObj));
                        }
                    }
                }
            }
            dispatch(
                fetchSpareParts({
                    populate: true,
                    'machine._id': { $in: [machineId] },
                })
            );
            setIsSparePartAddModalOpen(false);
        },
    });

    useEffect(() => {
        const fetchSpareOptions = async () => {
            const resp = await authAxiosInstance.get(`/sparePart`);
            setPartsData(resp?.data?.data?.docs);
        };
        fetchSpareOptions();
    }, [isSparePartAddModalOpen]);

    const spareOptions = spareData?.map((ele) => ({
        label: ele?.name,
        value: ele?._id,
    }));

    return (
        <ModalBasic
            modalOpen={isSparePartAddModalOpen}
            setModalOpen={setIsSparePartAddModalOpen}
        >
            <form
                className="p-4 flex flex-col gap-4"
                onSubmit={formik.handleSubmit}
            >
                <FormikSelectGroup
                    formik={formik}
                    label="Selected Machine"
                    name="machine"
                    options={
                        products?.docs
                            ? products?.docs?.map((ele) => ({
                                  label: `${ele.name}`,
                                  value: `${ele._id}`,
                              }))
                            : []
                    }
                    isDisabled
                />
                <FormikMultiSelect
                    formik={formik}
                    label="Spare Parts"
                    name="spareParts"
                    options={spareOptions}
                />
                <div className="mt-2">
                    <PrimaryButton type="submit">Save</PrimaryButton>
                </div>
            </form>
        </ModalBasic>
    );
};

export default AddSparePartToMachine;
