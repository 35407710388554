import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Copy, Edit } from 'react-feather';
import { useNavigate } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';

import {
    fetchQuotations,
    getQuotation,
} from '../../app/reducers/Quotation/quotationSlice';
import PageWithCard from '../../components/infrastructure/PageWithCard';
import TableWithHeadingAndSearch from '../../components/Table/TableWithHeadingAndSearch';
import ViewProductsModal from './ViewProductsModal';

const ViewQuotation = () => {
    const [isProductModalOpen, setIsProductModalOpen] = useState(false);
    const [quotationProductsData, setQuotationProductsData] = useState([]);

    const { quotation, loading } = useSelector(getQuotation);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        dispatch(
            fetchQuotations({ approvalStatus: 'approved', status: 'pending' })
        );
    }, []);

    const columns = [
        {
            Header: 'SrNo',
            Cell: ({ row }) => {
                return row.index + quotation?.pagingCounter;
            },
        },
        {
            Header: 'Quotation No',
            accessor: 'ref_no',
        },
        {
            Header: 'Customer Name',
            accessor: 'customerName',
        },
        {
            Header: 'Contact No',
            accessor: 'contactNumber',
        },
        {
            Header: 'Address',
            accessor: 'address',
        },
        {
            Header: 'Products',
            Cell: ({ row }) => {
                return (
                    <div
                        onClick={(e) => {
                            e.stopPropagation();
                            setIsProductModalOpen(true);
                            setQuotationProductsData(row?.original?.products);
                        }}
                        className="text-blue-400 underline hover:text-blue-700 cursor-pointer"
                    >
                        View Products
                    </div>
                );
            },
        },
        {
            Header: 'Total Products Price',
            Cell: ({ row }) => {
                const totalProductPrice = row?.original?.products?.reduce(
                    (acc, crr) => {
                        acc += parseFloat(crr.totalPrice || 0);
                        return acc;
                    },
                    0
                );

                return totalProductPrice;
            },
        },
        {
            Header: 'Action',
            Cell: ({ row }) => {
                return (
                    <div className="flex flex-row gap-3 cursor-pointer">
                        <Copy
                            onClick={(e) => {
                                e.stopPropagation();
                                navigate(
                                    `/quotation/duplicate-quotation/${row.original._id}`
                                );
                            }}
                        />
                        <Edit
                            onClick={(e) => {
                                e.stopPropagation();
                                navigate(
                                    `/quotation/edit-quotation/${row.original._id}`
                                );
                            }}
                            color="blue"
                        />
                    </div>
                );
            },
        },
    ];

    const colsMemo = useMemo(() => columns ?? [], [columns]);
    const dataMemo = useMemo(
        () => (quotation?.docs ? quotation.docs : []),
        [quotation]
    );
    return (
        <PageWithCard heading="View Quotation">
            <ViewProductsModal
                openModal={isProductModalOpen}
                setOpenModal={setIsProductModalOpen}
                products={quotationProductsData}
            />
            {}
            <TableWithHeadingAndSearch
                columns={colsMemo}
                data={dataMemo}
                heading={loading ? <ClipLoader /> : 'View Quotation'}
            />
        </PageWithCard>
    );
};

export default ViewQuotation;
