import React, { useEffect, useState } from 'react';
import PageWithCard from '../../components/infrastructure/PageWithCard';
import { FieldArray, FormikProvider, useFormik } from 'formik';
import FormikAsyncSelect from '../../components/formik/FormikAsyncSelect';
import QueryString from 'qs';
import { authAxiosInstance } from '../../utils/axiosConfig';
import FormikSelectGroup from '../../components/formik/FormikSelectGroup';
import ReactSelect from 'react-select/async';
import { useDispatch, useSelector } from 'react-redux';
import {
    fetchCustomer,
    fetchCustomers,
    getCustomers,
} from '../../app/reducers/Customers/customerSlice';
import { generateOptions } from '../../utils/Utils';
import FormikTextareaGroup from '../../components/formik/FormikTextareaGroup';
import FormikDirectImageUpload from '../../components/formik/FormikDirectImageUpload';
import { X } from 'react-feather';
import FormikInputGroup from '../../components/formik/FormikInputGroup';
import PrimaryButton from '../../components/infrastructure/Buttons/PrimaryButton';
import { createSparePartPurchaseOrder } from '../../app/reducers/PurchaseOrder/sparePartPurchaseOrderSlice';

const TableHeader = ({ headers }) => {
    return (
        <thead className="text-xs font-semibold uppercase text-slate-500 bg-slate-50 border-t border-b border-slate-200">
            <tr>
                {headers?.map((header) => (
                    <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                        <div className="font-semibold text-left">{header}</div>
                    </th>
                ))}
            </tr>
        </thead>
    );
};

const CreateSparePartPO = () => {
    const [customerOptions, setCustomerOptions] = useState([]);
    const [spareOptions, setSpareOptions] = useState([]);
    const [showSelectSparePart, setShowSelectSparePart] = useState(true);
    const [loading, setLoading] = useState(false);

    const { elementEditData } = useSelector(getCustomers);

    const dispatch = useDispatch();

    const formik = useFormik({
        initialValues: {
            company: '',
            customer: '',
            product: '',
            description: '',
            spareParts: [],
        },
        onSubmit: async(values,{ resetForm }) => {
            console.log('values', values);
            try {
                if(values?.spareParts?.length > 0){
                    setLoading(true);
                    let newSpareParts = values?.spareParts?.map((ele)=>{
                        return {
                            sparePartId : ele?.sparePartId,
                            quantity : ele?.newQuantity,
                        }
                    })
                    let obj = { 
                        ...values,
                        spareParts : newSpareParts
                    }
                    await dispatch(createSparePartPurchaseOrder(obj))
                    resetForm();
                    setLoading(false)
                }
                else {
                    alert('Please Select one Spare part');
                }                
            } catch (error) {
                console.log('Error', error)
                resetForm();
            } finally {
                setLoading(false)
            }
        },
    });

    useEffect(() => {
        dispatch(
            fetchCustomer({
                _id: formik?.values?.customer ?? '',
                populate: true,
            })
        );
    }, [JSON.stringify(formik?.values?.customer)]);

    return (
        <PageWithCard heading="Create Purchase Order for Spare Parts">
            <form
                onSubmit={formik.handleSubmit}
                className="flex flex-col gap-4"
            >
                <FormikAsyncSelect
                    label="Select Company"
                    name={'company'}
                    formik={formik}
                    getOptions={async (value) => {
                        try {
                            const string = QueryString.stringify({
                                search: value,
                            });
                            const response = await authAxiosInstance.get(
                                `/company?${string}`
                            );
                            const options = response?.data?.data?.docs?.map(
                                (ele) => ({
                                    label: ele.name,
                                    value: ele._id,
                                })
                            );

                            return options;
                        } catch (error) {
                            console.log(error);
                        }
                    }}
                    onChange={async (selectedOption) => {
                        const searchPayload = {
                            company: selectedOption.value,
                        };
                        const string = QueryString.stringify(searchPayload);
                        const customerResp = await authAxiosInstance.get(
                            `/customer?${string}`
                        );
                        const customerDocs = customerResp?.data?.data?.docs
                            ? customerResp?.data?.data?.docs
                            : [];
                        setCustomerOptions(customerDocs);
                        formik.setFieldValue('company', selectedOption.value);
                        formik.setFieldValue(
                            'customer',
                            customerDocs?.[0]?._id
                        );
                    }}
                />
                {formik.values.company && (
                    <>
                        <FormikSelectGroup
                            label="Select Customer"
                            name="customer"
                            formik={formik}
                            options={generateOptions({
                                array: customerOptions,
                                labelField: 'name',
                                valueField: '_id',
                            })}
                        />
                        {formik?.values?.customer && (
                            <FormikSelectGroup
                                label="Select Machine"
                                name="product"
                                formik={formik}
                                options={
                                    elementEditData?.machine
                                        ? elementEditData?.machine?.map(
                                              (ele) => ({
                                                  label: ele.name,
                                                  value: ele._id,
                                              })
                                          )
                                        : []
                                }
                                onChange={async (selectedOption) => {
                                    console.log('selected', selectedOption);

                                    const query = {
                                        populate: true,
                                        'machine._id': {
                                            $in: [selectedOption.value],
                                        },
                                    };
                                    const string = QueryString.stringify(query);
                                    const response =
                                        await authAxiosInstance.get(
                                            `/sparePart?${string}`
                                        );

                                    setSpareOptions(response?.data?.data?.docs);

                                    formik.setFieldValue(
                                        'product',
                                        selectedOption.value
                                    );
                                    formik.setFieldValue('spareParts', []);
                                }}
                            />
                        )}
                        {formik?.values?.product && (
                            <FormikTextareaGroup
                                name="description"
                                formik={formik}
                                label="Description"
                            />
                        )}
                        {formik?.values?.product && (
                            <FormikProvider value={formik}>
                                <FieldArray
                                    name="spareParts"
                                    render={(arrayHelpers) => {
                                        return (
                                            <>
                                                {showSelectSparePart ? (
                                                    <div className="">
                                                        {/* <FormikSelectGroup
                                                        formik={formik}
                                                        label="Select Spare Part"
                                                        name="sparePart"
                                                        options={
                                                            spareOptions?.length
                                                                ? spareOptions?.map(
                                                                      (
                                                                          ele
                                                                      ) => ({
                                                                          label: ele.name,
                                                                          value: ele._id,
                                                                      })
                                                                  )
                                                                : []
                                                        } */}
                                                        <FormikAsyncSelect
                                                            formik={formik}
                                                            label="Select Spare Part"
                                                            name="spareParts"
                                                            getOptions={async (
                                                                value
                                                            ) => {
                                                                try {
                                                                    const query =
                                                                        {
                                                                            search: value,
                                                                        };
                                                                    const string =
                                                                        QueryString.stringify(
                                                                            query
                                                                        );
                                                                    const resp =
                                                                        await authAxiosInstance.get(
                                                                            `/sparePart?${string}`
                                                                        );
                                                                    const options =
                                                                        resp?.data?.data?.docs?.map(
                                                                            (
                                                                                ele
                                                                            ) => ({
                                                                                label: `${ele.name}`,
                                                                                value: ele._id,
                                                                            })
                                                                        );

                                                                    return options;
                                                                } catch (error) {
                                                                    console.log(
                                                                        'error',
                                                                        error
                                                                    );
                                                                    toast.error(
                                                                        'Failed to get spare parts'
                                                                    );
                                                                }
                                                            }}
                                                            onChange={async (
                                                                selectedOption
                                                            ) => {
                                                                const query = {
                                                                    _id: selectedOption.value,
                                                                };
                                                                const string =
                                                                    QueryString.stringify(
                                                                        query
                                                                    );
                                                                const resp =
                                                                    await authAxiosInstance.get(
                                                                        `/sparePart?${string}`
                                                                    );
                                                                console.log(
                                                                    'resp',
                                                                    resp?.data
                                                                        ?.data
                                                                        ?.docs
                                                                );

                                                                const product =
                                                                    resp?.data
                                                                        ?.data
                                                                        ?.docs[0];
                                                                console.log(
                                                                    'product',
                                                                    product
                                                                );
                                                                console.log(
                                                                    'values spares',
                                                                    formik
                                                                        .values
                                                                        .spareParts
                                                                );
                                                                const existingProductIndex =
                                                                    formik.values.spareParts.findIndex(
                                                                        (ele) =>
                                                                            ele.sparePartId ==
                                                                            product._id
                                                                    );

                                                                if (
                                                                    existingProductIndex !==
                                                                    -1
                                                                ) {
                                                                    const existingProduct =
                                                                        formik
                                                                            .values
                                                                            .spareParts[
                                                                            existingProductIndex
                                                                        ];
                                                                    const updateQuantity =
                                                                        existingProduct.newQuantity +
                                                                        1;
                                                                    const updatedProducts =
                                                                        formik.values.spareParts.map(
                                                                            (
                                                                                ele,
                                                                                index
                                                                            ) =>
                                                                                index ==
                                                                                existingProductIndex
                                                                                    ? {
                                                                                          ...existingProduct,
                                                                                          newQuantity:
                                                                                              updateQuantity,
                                                                                      }
                                                                                    : ele
                                                                        );
                                                                    console.log(
                                                                        'updatedProducts',
                                                                        updatedProducts
                                                                    );
                                                                    formik.setFieldValue(
                                                                        'spareParts',
                                                                        updatedProducts
                                                                    );
                                                                    alert(
                                                                        'Spare Part already exists, quantity increased by 1.'
                                                                    );
                                                                } else {
                                                                    arrayHelpers.insert(
                                                                        0,
                                                                        {
                                                                            sparePartId:
                                                                                product?._id,
                                                                            quantity: 1,
                                                                            name: product.name,
                                                                            photo: product.photo,
                                                                            newQuantity: 1,
                                                                        }
                                                                    );
                                                                }
                                                                setShowSelectSparePart(
                                                                    false
                                                                );
                                                            }}
                                                        />
                                                    </div>
                                                ) : (
                                                    <div>
                                                        <PrimaryButton
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                setShowSelectSparePart(
                                                                    true
                                                                );
                                                            }}
                                                        >
                                                            Add More Spare part
                                                        </PrimaryButton>
                                                    </div>
                                                )}
                                                <table className="table-auto w-full border">
                                                    <TableHeader
                                                        headers={[
                                                            ' ',
                                                            'Image',
                                                            'Part Name',
                                                            'Quantity',
                                                        ]}
                                                    />
                                                    <tbody className="text-sm divide-y divide-slate-200">
                                                        {formik.values
                                                            .spareParts &&
                                                            formik.values.spareParts.map(
                                                                (ele, i) => (
                                                                    <tr
                                                                        key={
                                                                            ele.product
                                                                        }
                                                                    >
                                                                        <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                                                            <X
                                                                                onClick={() => {
                                                                                    arrayHelpers.remove(
                                                                                        i
                                                                                    );
                                                                                    console.log(
                                                                                        'length',
                                                                                        formik
                                                                                            .values
                                                                                            .spareParts
                                                                                            .length,
                                                                                        'and index is =',
                                                                                        i
                                                                                    );
                                                                                    if (
                                                                                        formik
                                                                                            .values
                                                                                            .spareParts
                                                                                            .length <=
                                                                                        1
                                                                                    ) {
                                                                                        setShowSelectSparePart(
                                                                                            true
                                                                                        );
                                                                                    }
                                                                                }}
                                                                            />
                                                                        </td>
                                                                        <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap border">
                                                                            <img
                                                                                src={`${process.env.REACT_APP_S3_URL}${ele.photo}`}
                                                                                alt={`spareParts${i}`}
                                                                                style={{
                                                                                    width: '50px',
                                                                                    height: '50px',
                                                                                }}
                                                                            />
                                                                        </td>
                                                                        <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                                                            <FormikInputGroup
                                                                                name={`spareParts.${i}.name`}
                                                                                formik={
                                                                                    formik
                                                                                }
                                                                                type="text"
                                                                                readOnly
                                                                            />
                                                                        </td>
                                                                        <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                                                            <FormikInputGroup
                                                                                name={`spareParts.${i}.newQuantity`}
                                                                                type="number"
                                                                                formik={
                                                                                    formik
                                                                                }
                                                                                // min={0}
                                                                                // max={formik.values.spareParts[i].stock}
                                                                            />
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            )}
                                                    </tbody>
                                                </table>
                                            </>
                                        );
                                    }}
                                ></FieldArray>
                            </FormikProvider>
                        )}
                    </>
                )}
                <div className="mt-4">
                    <PrimaryButton type="submit">Submit</PrimaryButton>
                </div>
            </form>
        </PageWithCard>
    );
};

export default CreateSparePartPO;
