import { createSlice } from '@reduxjs/toolkit';
import { generateSetEditId } from '../../generators/generateReducers';
import { generateStateSingle } from '../../generators/generateState';
import {
    createData,
    deleteData,
    editData,
    fetchData,
    fetchEditData,
} from '../../generators/generateThunks';
const SLICE_NAME = 'sparePart';

export const fetchSpareParts = fetchData.generateThunk(SLICE_NAME, SLICE_NAME);
export const editSparePart = editData.generateThunk(SLICE_NAME, SLICE_NAME);
export const createSparePart = createData.generateThunk(SLICE_NAME, SLICE_NAME);
export const deleteSparePart = deleteData.generateThunk(SLICE_NAME, SLICE_NAME);
export const fetchSparePart = fetchEditData.generateThunk(SLICE_NAME, SLICE_NAME);

const sparePartSlice = createSlice({
    name: SLICE_NAME,
    initialState: generateStateSingle(SLICE_NAME),
    extraReducers: {
        ...fetchData.generateExtraReducers(fetchSpareParts, SLICE_NAME),
        ...editData.generateExtraReducers(editSparePart, SLICE_NAME),
        ...createData.generateExtraReducers(createSparePart, SLICE_NAME),
        ...deleteData.generateExtraReducers(deleteSparePart, SLICE_NAME),
        ...fetchEditData.generateExtraReducers(fetchSparePart, SLICE_NAME),
    },
});

export const getSpareParts = (state) => state.sparePart;
export default sparePartSlice.reducer;
