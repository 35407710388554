import { createSlice } from '@reduxjs/toolkit';
import { generateSetEditId } from '../../generators/generateReducers';
import { generateStateSingle } from '../../generators/generateState';
import {
    createData,
    deleteData,
    editData,
    fetchData,
    fetchEditData,
} from '../../generators/generateThunks';
const SLICE_NAME = 'sparePartPurchaseOrder';

export const fetchSparePartPurchaseOrders = fetchData.generateThunk(
    SLICE_NAME,
    SLICE_NAME
);
export const editSparePartPurchaseOrder = editData.generateThunk(SLICE_NAME, SLICE_NAME);
export const createSparePartPurchaseOrder = createData.generateThunk(
    SLICE_NAME,
    SLICE_NAME
);
export const deleteSparePartPurchaseOrder = deleteData.generateThunk(
    SLICE_NAME,
    SLICE_NAME
);
export const fetchSparePartPurchaseOrder = fetchEditData.generateThunk(
    SLICE_NAME,
    SLICE_NAME
);

const sparePartPurchaseOrderSlice = createSlice({
    name: SLICE_NAME,
    initialState: generateStateSingle(SLICE_NAME),
    extraReducers: {
        ...fetchData.generateExtraReducers(fetchSparePartPurchaseOrders, SLICE_NAME),
        ...editData.generateExtraReducers(editSparePartPurchaseOrder, SLICE_NAME),
        ...createData.generateExtraReducers(createSparePartPurchaseOrder, SLICE_NAME),
        ...deleteData.generateExtraReducers(deleteSparePartPurchaseOrder, SLICE_NAME),
        ...fetchEditData.generateExtraReducers(fetchSparePartPurchaseOrder, SLICE_NAME),
    },
});

export const getSparePartPurchaseOrders = (state) => state.sparePartPurchaseOrder;
export default sparePartPurchaseOrderSlice.reducer;
