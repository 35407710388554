import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
    editApproval,
    fetchApprovals,
    getApproval,
} from '../../app/reducers/Approval/approvalSlice';
import ProductContainer from '../Users/ProductContainer';
import TableWithHeadingAndSearch from '../../components/Table/TableWithHeadingAndSearch';
import PrimaryButton from '../../components/infrastructure/Buttons/PrimaryButton';
import PageWithCard from '../../components/infrastructure/PageWithCard';
import { ClipLoader } from 'react-spinners';
import { getAuth } from '../../app/reducers/Auth/authSlice';

const ViewApproval = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalData, setModalData] = useState([]);

    const { user } = useSelector(getAuth);
    const { approval, loading } = useSelector(getApproval);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchApprovals({ populate: true, status: 'pending' }));
    }, []);

    const columns = [
        {
            Header: 'Sr.no',
            Cell: ({ row }) => {
                return row.index + approval.pagingCounter;
            },
        },
        {
            Header: 'Company Name',
            accessor: 'company.name',
        },
        {
            Header: 'Machine',
            Cell: ({ row }) => {
                return (
                    <div
                        onClick={(e) => {
                            e.stopPropagation();
                            setIsModalOpen(true);
                            setModalData(row?.original?.company?.machine);
                        }}
                        className="text text-blue-700 underline hover:cursor-pointer"
                    >
                        View Machine
                    </div>
                );
            },
        },
        {
            Header: 'INK',
            Cell: ({ row }) => {
                return (
                    <div
                        onClick={(e) => {
                            e.stopPropagation();
                            setIsModalOpen(true);
                            setModalData(row?.original?.company?.ink);
                        }}
                        className="text text-blue-700 underline hover:cursor-pointer"
                    >
                        View Ink
                    </div>
                );
            },
        },
        {
            Header: 'Previous Assign Executive',
            Cell: ({ row }) => {
                return row?.original?.previousExecutiveUsers?.map((el, i) => (
                    <div key={el._id}>
                        {`${el.firstName} ${el.lastName}`}
                        {i !== row?.original?.previousExecutiveUsers?.length - 1
                            ? ','
                            : ''}
                    </div>
                ));
            },
        },
        {
            Header: 'Current Assign Executive',
            Cell: ({ row }) => {
                return row?.original?.executiveUsers?.map((el, i) => (
                    <div key={el._id}>
                        {`${el.firstName} ${el.lastName}`}
                        {i !== row?.original?.executiveUsers?.length - 1
                            ? ','
                            : ''}
                    </div>
                ));
            },
        },
        {
            Header: 'Action',

            Cell: ({ row }) => {
                return (
                    <div>
                        {user?.role === 'sup_admin' && (
                            <PrimaryButton
                                type="button"
                                onClick={async (e) => {
                                    e.stopPropagation();
                                    await dispatch(editApproval(row.original));
                                    await dispatch(
                                        fetchApprovals({
                                            populate: true,
                                            status: 'pending',
                                        })
                                    );
                                }}
                            >
                                {loading ? <ClipLoader /> : 'Approve'}
                            </PrimaryButton>
                        )}
                    </div>
                );
            },
        },
    ];

    const colsMemo = useMemo(() => columns ?? [], [columns]);
    const dataMemo = useMemo(
        () => (approval?.docs ? approval.docs : []),
        [approval]
    );
    return (
        <PageWithCard>
            <ProductContainer
                modalData={modalData}
                setIsModalOpen={setIsModalOpen}
                isModalOpen={isModalOpen}
            />
            <TableWithHeadingAndSearch
                data={dataMemo}
                columns={colsMemo}
                heading={'View Approval'}
            />
        </PageWithCard>
    );
};

export default ViewApproval;
