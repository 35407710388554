import React, { useEffect, useMemo, useState } from 'react';
import PageWithCard from '../../components/infrastructure/PageWithCard';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
    editSparePart,
    fetchSparePart,
    fetchSpareParts,
    getSpareParts,
} from '../../app/reducers/SparePart/sparePartSlice';
import FormikSelectGroup from '../../components/formik/FormikSelectGroup';
import { useFormik } from 'formik';
import {
    fetchProducts,
    getProducts,
} from '../../app/reducers/Products/productSlice';
import TableWithHeadingAndSearch from '../../components/Table/TableWithHeadingAndSearch';
import PaginationClassic from '../../components/infrastructure/pagination/PaginationClassic';
import { Trash } from 'react-feather';
import ProductImageViewModal from './ProductImageViewModal';
import PrimaryButton from '../../components/infrastructure/Buttons/PrimaryButton';
import SecondaryButton from '../../components/infrastructure/Buttons/SecondaryButton';
import AddSparePartToMachine from './AddSparePartToMachine';
import FormikAsyncSelect from '../../components/formik/FormikAsyncSelect';
import { toast } from 'react-toastify';
import QueryString from 'qs';
import { authAxiosInstance } from '../../utils/axiosConfig';

const MachineSparePartsTable = () => {
    const [page, setPage] = useState(1);
    const [imageData, setImageData] = useState({});
    const [partsData, setPartsData] = useState([]);
    const [machineId, setMachineId] = useState([]);
    const [isImageModalOpen, setIsImageModalOpen] = useState(false);
    const [isSparePartAddModalOpen, setIsSparePartAddModalOpen] =
        useState(false);

    const { sparePart } = useSelector(getSpareParts);
    const { products } = useSelector(getProducts);
    const { id } = useParams();

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            machine: id ? id : '',
            spareParts: '',
        },
        onSubmit: async (values) => {
            console.log('values', values);
            const existingIds = sparePart?.docs?.map((ele) => ele?._id);

            if (existingIds.includes(values?.spareParts)) {
                toast.error('Spare-Part already exist !!');
                return;
            }
            let res = await dispatch(
                fetchSparePart({ _id: values?.spareParts })
            );

            if (res?.payload?.success) {
                let fetchObj = res?.payload?.data?.docs[0];
                let editObj = {
                    ...fetchObj,
                    machine: [...fetchObj.machine, values?.machine],
                };
                await dispatch(editSparePart(editObj));
            }
            await dispatch(
                fetchSpareParts({
                    populate: true,
                    page,
                    'machine._id': { $in: [id] },
                    sort : { name : 1},
                })
            );
        },
    });

    useEffect(() => {
        if (id) {
            dispatch(
                fetchSpareParts({
                    populate: true,
                    page,
                    'machine._id': { $in: [id] },
                    sort : { name : 1},
                })
            );
        }
        dispatch(fetchProducts({ populate: true, type: { $ne: 'INK' } }));
    }, [id]);

    const cols = [
        {
            Header: 'Parts Name',
            accessor: 'name',
        },
        {
            Header: 'Parts Image',
            Cell: ({ row }) => {
                // console.log('row', row?.original)
                return (
                    <div
                        className="text text-blue-700 underline hover:cursor-pointer"
                        onClick={(e) => {
                            e.stopPropagation();
                            setIsImageModalOpen(true);
                            setImageData(row?.original);
                        }}
                    >
                        View Parts
                    </div>
                );
            },
        },
        // {
        //     Header : 'Machine',
        //     Cell: ({ row }) => {
        //         return (
        //             <div
        //                 onClick={(e) => {
        //                     e.stopPropagation();
        //                     setIsModalOpen(true);
        //                     setModalData(row?.original?.machine);
        //                 }}
        //                 className="text text-blue-700 underline hover:cursor-pointer"
        //             >
        //                 View Machine
        //             </div>
        //         );
        //     },
        // },
        {
            Header: 'Action',
            Cell: ({ row }) => {
                return (
                    <div className="flex flex-row items-center gap-2">
                        <Trash
                            color="red"
                            onClick={async (e) => {
                                e.stopPropagation();
                                let res = await dispatch(
                                    fetchSparePart({ _id: row?.original?._id })
                                );
                                if (res?.payload?.success) {
                                    let fetchObj = res?.payload?.data?.docs[0];
                                    let editMachine = fetchObj.machine.filter(
                                        (ele) => ele !== id
                                    );
                                    let editObj = {
                                        ...fetchObj,
                                        machine: editMachine,
                                    };
                                    await dispatch(editSparePart(editObj));
                                }
                                dispatch(
                                    fetchSpareParts({
                                        populate: true,
                                        page,
                                        'machine._id': { $in: [id] },
                                        sort : { name : 1},
                                    })
                                );
                            }}
                        />
                    </div>
                );
            },
        },
    ];

    const sparePartsData = useMemo(() => {
        if (sparePart && sparePart?.docs) {
            return sparePart?.docs;
        } else return [];
    }, [sparePart]);

    return (
        <PageWithCard>
            <ProductImageViewModal
                modalData={imageData}
                isImageModalOpen={isImageModalOpen}
                setIsImageModalOpen={setIsImageModalOpen}
            />
            <AddSparePartToMachine
                partsData={partsData}
                machineId={machineId}
                isSparePartAddModalOpen={isSparePartAddModalOpen}
                setIsSparePartAddModalOpen={setIsSparePartAddModalOpen}
            />
            <form
                className="flex flex-col gap-3"
                onSubmit={formik.handleSubmit}
            >
                <div>
                    <label className="font-medium">Selected Machine</label>
                    <div className="w-full flex flex-col md:flex-row items-end gap-4">
                        <div className="w-full sm:w-[60%] md:w-[70%] lg:w-[80%]">
                            <FormikSelectGroup
                                formik={formik}
                                // label="Selected Machine"
                                name="machine"
                                options={
                                    products?.docs
                                        ? products?.docs?.map((ele) => ({
                                              label: `${ele.name}`,
                                              value: `${ele._id}`,
                                          }))
                                        : []
                                }
                                isDisabled
                            />
                        </div>
                        <div className="w-full sm:w-[40%] md:w-[30%] lg:w-[20%]">
                            <SecondaryButton
                                className="md:w-full"
                                type="button"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    navigate(`/product/sparePartsMapping`);
                                }}
                            >
                                Change Machine
                            </SecondaryButton>
                        </div>
                    </div>
                </div>
                <FormikAsyncSelect
                    formik={formik}
                    label="Add More Spare Part"
                    name="spareParts"
                    getOptions={async (value) => {
                        try {
                            const query = {
                                search: value,
                            };
                            const string = QueryString.stringify(query);
                            const resp = await authAxiosInstance.get(
                                `/sparePart?${string}`
                            );
                            const options = resp?.data?.data?.docs?.map(
                                (ele) => ({
                                    label: `${ele.name}`,
                                    value: ele._id,
                                })
                            );

                            return options;
                        } catch (error) {
                            console.log('error', error);
                            toast.error('Failed to get spare parts');
                        }
                    }}
                    // onChange={async (selectedOption) => {
                    //     const existingIds = sparePart?.docs?.map(
                    //         (ele) => ele?._id
                    //     );

                    //     if (existingIds.includes(selectedOption.value)) {
                    //         toast.error('Selected SparePart already exist !!');
                    //         return;
                    //     }
                    //     let res = await dispatch(
                    //         fetchSparePart({ _id: selectedOption.value })
                    //     );

                    //     if (res?.payload?.success) {
                    //         let fetchObj = res?.payload?.data?.docs[0];
                    //         let editObj = {
                    //             ...fetchObj,
                    //             machine: [...fetchObj.machine, id],
                    //         };
                    //         await dispatch(editSparePart(editObj));
                    //     }
                    //     await dispatch(
                    //         fetchSpareParts({
                    //             populate: true,
                    //             page,
                    //             'machine._id': { $in: [id] },
                    //         })
                    //     );
                    // }}
                />
                <div className="mt-2">
                    <PrimaryButton type="submit">Submit</PrimaryButton>
                </div>
            </form>
            <div className="mt-4">
                <TableWithHeadingAndSearch
                    columns={cols}
                    data={sparePartsData}
                />
                <PaginationClassic
                    paginationDetails={sparePart}
                    setPage={page}
                />
            </div>
        </PageWithCard>
    );
};

export default MachineSparePartsTable;
