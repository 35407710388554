import React, { useEffect, useState } from 'react';
import { Formik, useFormik } from 'formik';
import ModalBasic from '../../components/infrastructure/Modals/ModalBasic';
import { useDispatch, useSelector } from 'react-redux';
import FormikInputGroup from '../../components/formik/FormikInputGroup';
import PrimaryButton from '../../components/infrastructure/Buttons/PrimaryButton';
import { ClipLoader } from 'react-spinners';
import FormikDirectImageUpload from '../../components/formik/FormikDirectImageUpload';
import {
    editSparePart,
    fetchSparePart,
    fetchSpareParts,
    getSpareParts,
    resetSparePartEditData,
} from '../../app/reducers/SparePart/sparePartSlice';
import FormikMultiSelect from '../../components/formik/FormikMultiSelect';
import {
    fetchProducts,
    getProducts,
} from '../../app/reducers/Products/productSlice';

const EditSpareParts = ({
    productEditId,
    setProductEditId,
    page,
    showEditProductModal,
    setShowEditProductModal,
}) => {
    const { elementEditData, editDataLoading } = useSelector(getSpareParts);
    const { products } = useSelector(getProducts);

    const dispatch = useDispatch();

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {...elementEditData},
        onSubmit: async (values) => {
            console.log(values);
            await dispatch(editSparePart(values));
            await dispatch(
                fetchSpareParts({ populate: true, page, limit: 500 })
            );

            setShowEditProductModal(false);
        },
    });

    useEffect(() => {
        if (productEditId) {
            dispatch(fetchSparePart({ _id: productEditId }));
        } 
        else {
            setProductEditId(null);
        }
    }, [productEditId]);

    return (
        <ModalBasic
            modalOpen={showEditProductModal}
            setModalOpen={setShowEditProductModal}
        >
            {editDataLoading ? (
                <ClipLoader />
            ) : (
                <form
                    onSubmit={formik.handleSubmit}
                    className="flex flex-col gap-4 p-4"
                >
                    <FormikInputGroup
                        formik={formik}
                        name="name"
                        label="Name"
                        required
                    />
                    <FormikDirectImageUpload
                        formik={formik}
                        label="Photo"
                        name="photo"
                        location={'iml_group/spare_parts'}
                        randomize
                    />
                    <FormikMultiSelect
                        formik={formik}
                        label="Machine"
                        name="machine"
                        options={
                            products?.docs
                                ? products?.docs?.map((ele) => ({
                                      label: `${ele.name}`,
                                      value: `${ele._id}`,
                                  }))
                                : []
                        }
                    />
                    <div>
                        <PrimaryButton
                            type="submit"
                            disabled={formik.isSubmitting}
                        >
                            Edit
                        </PrimaryButton>
                    </div>
                </form>
            )}
        </ModalBasic>
    );
};

export default EditSpareParts;
