import { createSlice } from '@reduxjs/toolkit';
import { generateSetEditId } from '../../generators/generateReducers';
import { generateStateSingle } from '../../generators/generateState';
import {
    createData,
    deleteData,
    editData,
    fetchData,
    fetchEditData,
} from '../../generators/generateThunks';
const SLICE_NAME = 'quotation';

export const fetchQuotations = fetchData.generateThunk(SLICE_NAME, SLICE_NAME);
export const editQuotation = editData.generateThunk(SLICE_NAME, SLICE_NAME);
export const createQuotation = createData.generateThunk(SLICE_NAME, SLICE_NAME);
export const deleteQuotation = deleteData.generateThunk(SLICE_NAME, SLICE_NAME);
export const fetchQuotation = fetchEditData.generateThunk(
    SLICE_NAME,
    SLICE_NAME
);

const quotationsSlice = createSlice({
    name: SLICE_NAME,
    initialState: generateStateSingle(SLICE_NAME),
    extraReducers: {
        ...fetchData.generateExtraReducers(fetchQuotations, SLICE_NAME),
        ...editData.generateExtraReducers(editQuotation, SLICE_NAME),
        ...createData.generateExtraReducers(createQuotation, SLICE_NAME),
        ...deleteData.generateExtraReducers(deleteQuotation, SLICE_NAME),
        ...fetchEditData.generateExtraReducers(fetchQuotation, SLICE_NAME),
    },
});

export const getQuotation = (state) => state.quotation;
export default quotationsSlice.reducer;
