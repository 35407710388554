import { useCallback, useEffect, useMemo, useState } from 'react';
import PageWithCard from '../../components/infrastructure/PageWithCard';
import FormikInputGroup from '../../components/formik/FormikInputGroup';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import {
    createProduct,
    deleteProduct,
    fetchProducts,
    getProducts,
} from '../../app/reducers/Products/productSlice';
import PrimaryButton from '../../components/infrastructure/Buttons/PrimaryButton';
import ShowDropdown from '../../components/infrastructure/ShowDropdown';
import TableWithHeadingAndGlobalSearch from '../../components/Table/TableWithHeadingAndGlobalSearch';
import PaginationClassic from '../../components/infrastructure/pagination/PaginationClassic';
import FormikSelectGroup from '../../components/formik/FormikSelectGroup';
import { PRODUCT_TYPE } from '../../utils/dropdownOptions';
import { Edit2, Trash2 } from 'react-feather';
import EditProduct from './EditProduct';
import FormikDirectImageUpload from '../../components/formik/FormikDirectImageUpload';

const AddProduct = () => {
    const dispatch = useDispatch();
    const { products, loading } = useSelector(getProducts);

    const [showEditProductModal, setShowEditProductModal] = useState(false);
    const [productEditId, setProductEditId] = useState(null);
    const [page, setPage] = useState(1);

    useEffect(() => {
        dispatch(fetchProducts({ populate: true, page, sort: { type: 1 } }));
    }, [page]);

    const debouncedSearch = useCallback(
        _.debounce((search) => {
            dispatch(
                fetchProducts({
                    populate: true,
                    page,
                    search,
                })
            );
        }, 300),
        [products?.docs]
    );

    const formik = useFormik({
        initialValues: {
            name: '',
            type: '',
            photo: '',
        },
        onSubmit: async (values) => {
            await dispatch(createProduct(values));
            await dispatch(
                fetchProducts({ populate: true, page, sort: { type: 1 } })
            );
        },
    });

    const cols = [
        {
            Header: 'Photo',
            Cell: ({ row }) => {
                return (
                    <div>
                        {row?.original?.photo ? (
                            <img
                                src={`${process.env.REACT_APP_S3_URL}${row.original.photo}`}
                                style={{ maxWidth: '170px' }}
                            />
                        ) : (
                            <p>no image</p>
                        )}
                    </div>
                );
            },
        },
        {
            Header: 'Name',
            accessor: 'name',
        },
        {
            Header: 'Type',
            accessor: 'type',
        },
        {
            Header: 'Action',
            Cell: ({ row }) => {
                return (
                    <div className="flex gap-2">
                        <Edit2
                            color="blue"
                            onClick={(e) => {
                                e.stopPropagation();
                                setShowEditProductModal(true);
                                setProductEditId(row.original._id);
                            }}
                        />
                        <Trash2
                            color="red"
                            onClick={async () => {
                                await dispatch(
                                    deleteProduct({ id: row.original._id })
                                );
                                dispatch(
                                    fetchProducts({
                                        populate: true,
                                        page,
                                        sort: { type: 1 },
                                    })
                                );
                            }}
                        />
                    </div>
                );
            },
        },
    ];

    const data = useMemo(
        () => (products?.docs ? products?.docs : []),
        [products]
    );
    return (
        <PageWithCard>
            <EditProduct
                productEditId={productEditId}
                setProductEditId={setProductEditId}
                page={page}
            />
            <div>
                <ShowDropdown
                    heading={'Add Products'}
                    allowedRoles={['admin', 'sup_admin']}
                >
                    <form
                        onSubmit={formik.handleSubmit}
                        className="flex flex-col gap-4 mb-4"
                    >
                        <FormikDirectImageUpload
                            label="Photo"
                            name={'photo'}
                            location={'iml_group/products'}
                            randomize
                            formik={formik}
                        />
                        <FormikInputGroup
                            formik={formik}
                            name={'name'}
                            label="Name"
                            required
                        />
                        <FormikSelectGroup
                            formik={formik}
                            label="Type"
                            name={'type'}
                            options={PRODUCT_TYPE}
                            required
                        />
                        <div>
                            <PrimaryButton
                                type="submit"
                                disabled={formik.isSubmitting}
                            >
                                Submit
                            </PrimaryButton>
                        </div>
                    </form>
                </ShowDropdown>
                <TableWithHeadingAndGlobalSearch
                    loading={loading}
                    data={data}
                    columns={cols}
                    heading={'Products'}
                    searchFunction={debouncedSearch}
                />
                <PaginationClassic
                    paginationDetails={products}
                    setPage={setPage}
                />
            </div>
        </PageWithCard>
    );
};

export default AddProduct;
