import React from 'react';
import ModalBasic from '../../components/infrastructure/Modals/ModalBasic';

const ProductImageViewModal = ({
    modalData,
    isImageModalOpen,
    setIsImageModalOpen,
}) => {
    return (
        <ModalBasic
            modalOpen={isImageModalOpen}
            setModalOpen={setIsImageModalOpen}
            max_width={'max-w-xs'}
        >
            <div className="p-4">
                {modalData?.photo ? (
                    <>
                        <img
                            src={`${process.env.REACT_APP_S3_URL}${modalData.photo}`}
                            style={{
                                width: '300px',
                                height: 'auto',
                            }}
                        />
                        <p className="text text-base font-semibold text-left">
                            {modalData?.name}
                        </p>
                    </>
                ) : (
                    <span>Image not Available !</span>
                )}
            </div>
        </ModalBasic>
    );
};

export default ProductImageViewModal;
