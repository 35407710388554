import React, { useEffect } from 'react';
import PageWithCard from '../../components/infrastructure/PageWithCard';
import { useDispatch, useSelector } from 'react-redux';
import {
    fetchProducts,
    getProducts,
} from '../../app/reducers/Products/productSlice';
import { useFormik } from 'formik';
import FormikSelectGroup from '../../components/formik/FormikSelectGroup';
import QueryString from 'qs';
import { authAxiosInstance } from '../../utils/axiosConfig';
import FormikMultiSelect from '../../components/formik/FormikMultiSelect';
import { useNavigate } from 'react-router-dom';

const SparePartsMapping = () => {
    const { products } = useSelector(getProducts);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const formik = useFormik({
        initialValues: {
            machine: '',
            spareParts: [],
        },
        onSubmit: (values) => {
            console.log('values', values);
        },
    });

    useEffect(() => {
        dispatch(fetchProducts({ populate: true, type: { $ne: 'INK' } }));
    }, []);

    return (
        <PageWithCard heading="Spare Parts Map with Machine">
            <form onSubmit={formik.handleSubmit}>
                <FormikSelectGroup
                    formik={formik}
                    label="Machine"
                    name="machine"
                    options={
                        products?.docs
                            ? products?.docs?.map((ele) => ({
                                  label: `${ele.name}`,
                                  value: `${ele._id}`,
                              }))
                            : []
                    }
                    onChange={async(selectedOption)=>{
                        console.log('selected', selectedOption)

                        // const query = {
                        //     populate : true,
                        //     'machine._id' : {$in : [selectedOption.value]}
                        // }
                        // const string = QueryString.stringify(query);
                        // const response = await authAxiosInstance.get(
                        //     `/sparePart?${string}`
                        // );
                        // console.log('resp', response?.data?.data?.docs)
                        formik.setFieldValue('machine', selectedOption.value)
                        if(selectedOption.value){
                            navigate(`/product/machineSpareParts/${selectedOption.value}`)
                        }
                    }}
                />
                {/* <FormikMultiSelect 
                    formik={formik}
                    label="Spare Parts"
                    name="spareParts"
                    options={}
                /> */}
            </form>
        </PageWithCard>
    );
};

export default SparePartsMapping;
