import React, { useMemo } from 'react';
import ModalBasic from '../../components/infrastructure/Modals/ModalBasic';
import TableWithHeadingAndSearch from '../../components/Table/TableWithHeadingAndSearch';

const ViewProductsModal = ({ products, openModal, setOpenModal }) => {
    const cols = [
        {
            Header: 'SrNo',
            Cell: ({ row }) => {
                return row.index + 1;
            },
        },
        {
            Header: 'Product Name',
            accessor: 'productName',
        },
        {
            Header: 'Quantity',
            accessor: 'quantity',
        },
        {
            Header: 'Price',
            accessor: 'price',
        },
        {
            Header: 'Tax',
            accessor: 'tax',
        },
        {
            Header: 'Unit Price',
            accessor: 'unitPrice',
        },
        {
            Header: 'Total Price',
            accessor: 'totalPrice',
        },
    ];
    const columnsMemo = useMemo(() => cols ?? [], [cols]);
    const productDataMemo = useMemo(() => products ?? [], [products]);
    return (
        <ModalBasic modalOpen={openModal} setModalOpen={setOpenModal}>
            <div className="p-4">
                <TableWithHeadingAndSearch
                    data={productDataMemo}
                    columns={columnsMemo}
                    heading={'Quotation Products'}
                />
            </div>
        </ModalBasic>
    );
};

export default ViewProductsModal;
