import React, { useEffect, useMemo, useState } from 'react';
import PageWithCard from '../../components/infrastructure/PageWithCard';
import ShowDropdown from '../../components/infrastructure/ShowDropdown';
import FormikInputGroup from '../../components/formik/FormikInputGroup';
import * as Yup from 'yup';
import { FieldArray, FormikProvider, useFormik } from 'formik';
import FormikDirectImageUpload from '../../components/formik/FormikDirectImageUpload';
import FormikAsyncSelect from '../../components/formik/FormikAsyncSelect';
import { authAxiosInstance } from '../../utils/axiosConfig';
import QueryString from 'qs';
import DangerButton from '../../components/infrastructure/Buttons/DangerButton';
import SecondaryButton from '../../components/infrastructure/Buttons/SecondaryButton';
import PrimaryButton from '../../components/infrastructure/Buttons/PrimaryButton';
import { useDispatch, useSelector } from 'react-redux';
import {
    createSparePart,
    deleteSparePart,
    fetchSpareParts,
    getSpareParts,
} from '../../app/reducers/SparePart/sparePartSlice';
import TableWithHeadingAndSearch from '../../components/Table/TableWithHeadingAndSearch';
import PaginationClassic from '../../components/infrastructure/pagination/PaginationClassic';
import FormikMultiSelect from '../../components/formik/FormikMultiSelect';
import {
    fetchProducts,
    getProducts,
} from '../../app/reducers/Products/productSlice';
import ProductImageViewModal from './ProductImageViewModal';
import ProductContainer from '../Users/ProductContainer';
import { Edit2, Trash } from 'react-feather';
import EditSpareParts from './EditSpareParts';

const SpareParts = () => {
    const [page, setPage] = useState(1);
    const [imageData, setImageData] = useState({});
    const [isImageModalOpen, setIsImageModalOpen] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalData, setModalData] = useState([]);
    const [showEditProductModal, setShowEditProductModal] = useState(false);
    const [productEditId, setProductEditId] = useState(null);

    const { products } = useSelector(getProducts);
    const { sparePart } = useSelector(getSpareParts);

    const dispatch = useDispatch();

    const formik = useFormik({
        initialValues: {
            name: '',
            photo: '',
            machine: [],
        },
        validationSchema: Yup.object({
            name: Yup.string().required(),
        }),
        onSubmit: async (values) => {
            console.log('values', values);
            await dispatch(createSparePart(values));
            await dispatch(fetchSpareParts());
            formik.resetForm();
        },
    });

    useEffect(() => {
        dispatch(fetchSpareParts({ page, limit: 500, populate: true }));
        dispatch(fetchProducts({ type: { $ne: 'INK' }, populate: true }));
    }, [page]);

    const cols = [
        {
            Header: 'Parts Name',
            accessor: 'name',
        },
        {
            Header: 'Parts Image',
            Cell: ({ row }) => {
                // console.log('row', row?.original)
                return (
                    <div
                        className="text text-blue-700 underline hover:cursor-pointer"
                        onClick={(e) => {
                            e.stopPropagation();
                            setIsImageModalOpen(true);
                            setImageData(row?.original);
                        }}
                    >
                        View Parts
                    </div>
                );
            },
        },
        {
            Header: 'Machine',
            Cell: ({ row }) => {
                return (
                    <div
                        onClick={(e) => {
                            e.stopPropagation();
                            setIsModalOpen(true);
                            setModalData(row?.original?.machine);
                        }}
                        className="text text-blue-700 underline hover:cursor-pointer"
                    >
                        View Machine
                    </div>
                );
            },
        },
        {
            Header: 'Action',
            Cell: ({ row }) => {
                return (
                    <div className="flex flex-row items-center gap-2">
                        <Edit2
                            color="blue"
                            onClick={(e) => {
                                e.stopPropagation();
                                setShowEditProductModal(true);
                                setProductEditId(row?.original?._id);
                            }}
                        />
                        <Trash
                            color="red"
                            onClick={async (e) => {
                                e.stopPropagation();
                                await dispatch(
                                    deleteSparePart({ id: row?.original?._id })
                                );
                                dispatch(
                                    fetchSpareParts({
                                        page,
                                        limit: 500,
                                        populate: true,
                                    })
                                );
                            }}
                        />
                    </div>
                );
            },
        },
    ];

    const spareData = useMemo(() => {
        if (sparePart && sparePart?.docs?.length) {
            return sparePart?.docs;
        } else return [];
    }, [sparePart]);

    return (
        <PageWithCard heading="Add Spare Parts">
            <ProductImageViewModal
                modalData={imageData}
                isImageModalOpen={isImageModalOpen}
                setIsImageModalOpen={setIsImageModalOpen}
            />
            <ProductContainer
                modalData={modalData}
                setIsModalOpen={setIsModalOpen}
                isModalOpen={isModalOpen}
            />
            <EditSpareParts
                showEditProductModal={showEditProductModal}
                setShowEditProductModal={setShowEditProductModal}
                productEditId={productEditId}
                setProductEditId={setProductEditId}
                page={page}
            />
            <ShowDropdown
                heading={'Add Spare Parts'}
                allowedRoles={['admin', 'sup_admin']}
            >
                <form
                    onSubmit={formik.handleSubmit}
                    className="flex flex-col gap-4 mb-4"
                >
                    <FormikInputGroup
                        formik={formik}
                        name="name"
                        label="Name"
                        required
                    />
                    <FormikDirectImageUpload
                        formik={formik}
                        label="Photo"
                        name="photo"
                        location={'iml_group/spare_parts'}
                        randomize
                    />
                    <FormikMultiSelect
                        formik={formik}
                        label="Machine"
                        name="machine"
                        options={
                            products?.docs
                                ? products?.docs?.map((ele) => ({
                                      label: `${ele.name}`,
                                      value: `${ele._id}`,
                                  }))
                                : []
                        }
                    />
                    <div>
                        <PrimaryButton
                            type="submit"
                            disabled={formik.isSubmitting}
                        >
                            Submit
                        </PrimaryButton>
                    </div>
                </form>
            </ShowDropdown>

            <TableWithHeadingAndSearch columns={cols} data={spareData} />
            <PaginationClassic paginationDetails={sparePart} setPage={page} />
        </PageWithCard>
    );
};

export default SpareParts;
